import React from "react";
import { motion } from "framer-motion";

const footerVariants = {
  hidden: { opacity: 0.5 },
  visible: { opacity: 1, transition: { duration: 0.8, ease: 'easeInOut' } }
};

function Footer() {
  return (
    <motion.footer
      initial="hidden"
      animate="visible"
      variants={footerVariants}
      className="min-h-[5vh] bg-grey-dark font-sans content-center"
    >
      <div className="text-sm sm:text-lg text-center text-white bottom-0">
        &copy; {new Date().getFullYear()} Pahwa Arch Tech Services. All Rights Reserved.
      </div>
    </motion.footer>
  );
}

export default Footer;