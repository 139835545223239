import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.3, delayChildren: 0.5 },
  },
};

const projectImages = [
  process.env.PUBLIC_URL + "/assets/samples/sample_4.jpg",
  process.env.PUBLIC_URL + "/assets/samples/sample_1.jpg",
  process.env.PUBLIC_URL + "/assets/samples/sample_2.jpg",
  process.env.PUBLIC_URL + "/assets/samples/sample_3.jpg",
  process.env.PUBLIC_URL + "/assets/samples/sample_5.jpg",
];

const projectNames = [
  "BOB Call Center, Ahmedabad",
  "Rana Hospital, Bulandshahr",
  "BOB RSETI, Sawai Madhopur",
  "Viera Electronics, Noida",
  "Residence, Noida"
];

function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  // Function to preload images
  const preloadImages = (imageUrls) => {
    let loadedCount = 0;
    const totalImages = imageUrls.length;

    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedCount += 1;
        if (loadedCount === totalImages) {
          setImagesLoaded(true);
        }
      };
    });
  };

  // Function to handle image change and reset timer
  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
    // Reset the interval on click
    clearInterval(intervalId);
    const newIntervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % projectImages.length
      );
    }, 5000);
    setIntervalId(newIntervalId);
  };

  // Set interval to change images on initial render
  useEffect(() => {
    preloadImages(projectImages);

    const newIntervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % projectImages.length
      );
    }, 5000);
    setIntervalId(newIntervalId);

    return () => clearInterval(newIntervalId); // Clear interval on unmount
  }, []);

  if (!imagesLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col flex-grow relative overflow-hidden h-[95vh]">
      {/* Hero Section */}
      <section className="flex flex-col flex-grow relative">
        {/* Background Image */}
        <div className="absolute inset-0">
          <div
            className="absolute inset-0 h-full w-full bg-cover bg-center"
            style={{
              backgroundImage: `url(${projectImages[currentImageIndex]})`,
              transition: "background-image 2s ease-in-out",
            }}
          ></div>
          <div
            className="absolute inset-0 h-full w-full bg-cover bg-center blur-lg scale-110"
            style={{
              backgroundImage: `url(${projectImages[currentImageIndex]})`,
              transition: "background-image 2s ease-in-out",
              zIndex: -1,
            }}
          ></div>
          {/* Gradient Overlay */}
          <div
            className="absolute h-full inset-0 pointer-events-none"
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, transparent 20%, transparent 80%, rgba(0, 0, 0, 0.8) 100%)",
              zIndex: 1,
            }}
          ></div>
        </div>

        {/* Text Container */}
        <div className="relative container mx-auto h-full flex flex-col justify-between content-center text-center text-white z-10">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={staggerContainer}
            className="flex flex-col items-center flex-grow"
          >
            {/* Placeholder for any text or content */}
          </motion.div>

          {/* Clickable Lines - Positioned at the bottom left */}
          <motion.div className="absolute bottom-4 left-4 flex space-x-2">
            {projectImages.map((_, index) => (
              <motion.div
                key={index}
                onClick={() => handleImageChange(index)}
                className={`w-8 h-[2px] bg-white rounded cursor-pointer ${
                  currentImageIndex === index ? "bg-opacity-100" : "bg-opacity-50"
                }`}
                style={{ transition: "background-color 0.5s ease" }}
              ></motion.div>
            ))}
          </motion.div>

          {/* Displayed Name - Positioned above the lines */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, opacity: { duration: 0.5 } }}
            className="absolute bottom-10 left-4 text-sm sm:text-md md:text-lg lg:text-xl text-white link-underline"
          >
            <AnimatePresence mode="wait">
              <motion.span
                key={currentImageIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {projectNames[currentImageIndex]}
              </motion.span>
            </AnimatePresence>
          </motion.div>
        </div>
      </section>
    </div>
  );
}

export default Home;