import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const navbarVariants = {
  hidden: { y: -50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.6, ease: "easeInOut" },
  },
};

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLogoVisible, setIsLogoVisible] = useState(false); // Track if logo is in view
  const logoRef = useRef(null); // Ref for logo visibility check
  const location = useLocation(); // Get current route

  // Lazy load logo image
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsLogoVisible(true); // Set logo as visible when in viewport
            observer.unobserve(entry.target); // Stop observing after it becomes visible
          }
        });
      },
      { threshold: 0.1 }
    );

    if (logoRef.current) {
      observer.observe(logoRef.current);
    }

    // Cleanup the observer on unmount
    return () => {
      if (logoRef.current) {
        observer.unobserve(logoRef.current);
      }
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <motion.nav
        initial="hidden"
        animate="visible"
        variants={navbarVariants}
        className="fixed top-0 w-full z-50 backdrop-blur-lg"
      >
        <div className="relative w-full flex justify-between content-center pt-4 px-3">
          <Link to="/" className="text-md sm:text-lg md:text-xl lg:text-2xl font-bold flex items-center">
            <div className="flex items-center">
              {/* Lazy loaded logo */}
              <div ref={logoRef}>
                {isLogoVisible && (
                  <img
                    src={process.env.PUBLIC_URL + "/assets/logo.png"}
                    alt="Logo"
                    className="h-7 md:h-8 xl:h-9 inline-block"
                  />
                )}
              </div>
              <span className="text-rust m-1 sm:ml-2 company-name">
                PAHWA ARCH TECH SERVICES
              </span>
            </div>
          </Link>
          <div className="text-md sm:text-lg md:text-xl lg:text-2xl flex items-center text-rust-light">
            {/* Hamburger Menu Icon */}
            <button
              onClick={toggleMenu}
              className="hover:text-rust cursor-pointer content-center"
              aria-label="Toggle Menu"
            >
              {isMenuOpen ? (
                <i className="fas fa-times w-6 h-6 content-center" aria-hidden="true"></i> // Close icon
              ) : (
                <i className="fas fa-bars w-6 h-6 content-center" aria-hidden="true"></i> // Burger icon
              )}
            </button>
          </div>
        </div>
      </motion.nav>

      {/* Fullscreen Navigation Menu */}
      <motion.div
        className={`fixed z-40 inset-0 bg-grey-dark bg-opacity-100 flex flex-col justify-center items-center space-y-8 transition-transform duration-300 ease-in-out ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Menu Links with Hover and Active Underline Effect */}
        <Link
          to="/"
          className={`text-4xl text-white relative group link-underline ${
            location.pathname === "/" ? "link-active" : ""
          }`}
          onClick={toggleMenu}
        >
          <i className="fas fa-home"></i> {/* Home icon */}
          Home
        </Link>
        <Link
          to="/about"
          className={`text-4xl text-white relative group link-underline ${
            location.pathname === "/about" ? "link-active" : ""
          }`}
          onClick={toggleMenu}
        >
          <i className="fas fa-user"></i> {/* About icon */}
          About
        </Link>
        <Link
          to="/projects"
          className={`text-4xl text-white relative group link-underline ${
            location.pathname === "/projects" ? "link-active" : ""
          }`}
          onClick={toggleMenu}
        >
          <i className="fas fa-briefcase"></i> {/* Projects icon */}
          Projects
        </Link>
        <Link
          to="/services"
          className={`text-4xl text-white relative group link-underline ${
            location.pathname === "/services" ? "link-active" : ""
          }`}
          onClick={toggleMenu}
        >
          <i className="fas fa-tools"></i> {/* Services icon */}
          Services
        </Link>
        <Link
          to="/contact"
          className={`text-4xl text-white relative group link-underline ${
            location.pathname === "/contact" ? "link-active" : ""
          }`}
          onClick={toggleMenu}
        >
          <i className="fas fa-envelope"></i> {/* Contact icon */}
          Contact
        </Link>
      </motion.div>
    </>
  );
}

export default Navbar;
